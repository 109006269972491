<template>
    <div class="container">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item>管理后台</el-breadcrumb-item>
            <el-breadcrumb-item>组织架构</el-breadcrumb-item>
            <el-breadcrumb-item>部门管理</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card>
            <el-row class="catopt">
                <el-col :span="22">
                    <el-form :inline="true">
                        <el-form-item>
                            <el-select v-model="searchDepartment1" size="small" placeholder="一级部门" @change="choiceA">
                                <el-option
                                v-for="item in department1List"
                                :key="item.branch"
                                :label="item.branch"
                                :value="item.branch">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchDepartment2" v-if="department2List.length>0" size="small" placeholder="二级部门" @change="choiceB">
                                <el-option
                                v-for="item in department2List"
                                :key="item.branch"
                                :label="item.branch"
                                :value="item.branch">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="searchDepartment3" v-if="department3List.length>0" size="small" placeholder="三级部门">
                                <el-option
                                v-for="item in department3List"
                                :key="item.branch"
                                :label="item.branch"
                                :value="item.branch">
                                </el-option>
                            </el-select>
                        </el-form-item>                       
                        <el-form-item>
                            <el-button type="primary" size="small"  @click="search">查询</el-button>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" size="small"  @click="reset">重置</el-button>
                        </el-form-item>
                    </el-form>
                </el-col>
                <el-col :span="2">
                    <el-button type="primary" size="small"  @click="add">添加部门</el-button>
                </el-col>
            </el-row>
            <tree-table class="tree-table" :data="treeData" :columns="columns" :selection-type="false" :expand-type="false"  border show-index index-text="#" :show-row-hover="false">
                <template slot="isok" slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.branch_level==2">一级</el-tag>
                    <el-tag size="mini" type="success" v-else-if="scope.row.branch_level==3">二级</el-tag>
                    <el-tag size="mini" type="warning" v-else-if="scope.row.branch_level==4">三级</el-tag>
                    <el-tag size="mini" type="danger" v-else>四级</el-tag>
                </template>
                <template slot="order" slot-scope="scope">
                    <el-tag size="mini">{{scope.row.branch_admin}}</el-tag>
                </template>
                <template slot="people" slot-scope="scope">
                    <el-tag size="mini">{{scope.row.people_num}}</el-tag>
                </template>
                <template slot="time" slot-scope="scope">
                    <el-tag size="mini">{{scope.row.create_time}}</el-tag>
                </template>
                <template slot="opt" slot-scope="scope">
                    <el-button type="success" size="mini" @click="edit(scope.row.id,scope.row.branch)">编辑</el-button>
                    <el-button type="danger"  size="mini" @click="remove(scope.row.id,scope.row.branch)">删除</el-button>
                </template>
            </tree-table>            
        </el-card>
        <el-dialog
            title="添加部门"
            :visible.sync="dialogVisible"
            width="50%">
            <div>
                <el-form ref="form"  label-width="120px">                    
                    <el-form-item label="部门名称：">
                        <el-input v-model="department" placeholder="请输入部门名称"></el-input>
                    </el-form-item>
                    <el-form-item label="上级部门：" >
                        <el-cascader
                        v-model="selectedDepartment"
                        :options="p_data"
                        :props="cascaderProps"
                        @change="parentDepartmentChanged" 
                        :clearable="true" :change-on-select="true">
                        </el-cascader>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="close">取消</el-button>
                <el-button type="primary" @click="addDepartment">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="编辑部门"
            :visible.sync="dialogVisibleEdit"
            width="50%">
            <div>
                <el-form ref="form"  label-width="120px">                    
                    <el-form-item label="部门名称：">
                        <el-input v-model="departmentEdit" placeholder="请输入部门名称"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="closeEdit">取消</el-button>
                <el-button type="primary" @click="editDepartment">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import {bian_log} from '../api/log'
import {add_department,department_list,edit_department,remove_department} from '../api/department'
export default {
    data() {
        return {
            company:'',
            companyData:[],
            treeData:[],
            p_data:[],
            cascaderProps:{
                value:'branch',
                label:'branch',
                children:'children',
                expandTrigger:'hover'
            },
            selectedDepartment:[],
            columns:[
                {
                    label: '部门',
                    prop: 'branch',
                    width:'400'
                },
                {
                    label: '部门分级',
                    type: 'template',
                    template: 'isok',
                },
                {
                    label: '管理员',
                    type: 'template',
                    template: 'order',
                },
                {
                    label: '部门人数',
                    type: 'template',
                    template: 'people',
                },
                {
                    label: '创建时间',
                    type: 'template',
                    template: 'time',
                },
                {
                    label: '操作',
                    type: 'template',
                    template: 'opt',
                },
            ],
            dialogVisible:false,
            department:'',
            department1List:[],
            department2List:[],
            department3List:[],
            searchDepartment1:null,
            searchDepartment2:null,
            searchDepartment3:null,
            add_level:2,
            root_branch:'',
            p_branch:'',
            dialogVisibleEdit:false,
            departmentEdit:'',
            editId:'',
            editBranch:'',
        };
    },
    computed: {},
    methods: {
        search(){
            this.departmentList()
        },
        reset(){
            this.searchDepartment1=null
            this.searchDepartment2=null
            this.searchDepartment3=null
            this.department2List=[]
            this.department3List=[]
            this.departmentList()
        },
        choiceA(){
            this.searchDepartment2=null
            this.searchDepartment3=null
            this.department2List=[]
            this.department3List=[]
            this.department1List.forEach(item=>{
                if(item.branch==this.searchDepartment1){
                    this.department2List=item.children
                }
            })
        },
        choiceB(){
            this.searchDepartment3=null
            this.department3List=[]
            this.department2List.forEach(item=>{
                if(item.branch==this.searchDepartment2){
                    this.department3List=item.children
                }
            })
        },
        add(){
            this.dialogVisible=true;
        },
        addDepartment(){
            if(this.department==''){
                return this.$message.error('请输入部门名称')
            }
            add_department(this.company,this.department,this.add_level,this.root_branch,this.p_branch).then(res=>{
                if(res.status!=200){
                    return this.$message.error('添加部门失败')
                }
                this.dialogVisible=false;
                this.department=''
                this.departmentList()
				bian_log(`添加部门${this.department}`)
            })
        },
        departmentList(){
            department_list(this.company,this.searchDepartment1,this.searchDepartment2,this.searchDepartment3,this.searchDepartment4,).then(res=>{
                if(res.status!=200){
                    return this.$message.error('获取部门列表失败')
                }
                let branch=res.data.branch;
                branch.forEach(item=>{
                    item.children=JSON.parse(item.children)
                })
                this.companyData=branch;
                this.department1List=branch
                let branch_copy=JSON.stringify(branch)
                let p_branch=JSON.parse(branch_copy)
                p_branch.forEach(item=>{
                    item.children.forEach(t=>{
                        t.children.forEach(tt=>{
                            delete tt.children
                        })
                    })
                })
                this.p_data=p_branch;
                let searchData=res.data.searchData;
                if(this.searchDepartment1){
                    // this.treeData=searchData;
					branch.forEach(item => {
						if(item.branch==this.searchDepartment1){
							this.treeData=[item]
						}
					})
                }else{
                    searchData.forEach(item=>{
                        item.children=JSON.parse(item.children)
                    })
                    this.treeData=searchData
                }   
            })
        },
        close(){
            this.dialogVisible=false;
            this.department='';
        },
        parentDepartmentChanged(){
            this.add_level=this.selectedDepartment.length+2;
            this.root_branch=this.selectedDepartment[0];
            this.p_branch=this.selectedDepartment[this.selectedDepartment.length-1];
        },
        edit(id,oldBranch){
            this.dialogVisibleEdit=true;
            this.editId=id;
            this.editBranch=oldBranch;
        },
        remove(id,department_old){
            this.$confirm('此操作将永久删除该部门,删除后恢复，需手动重新设置, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                remove_department(id,this.company,department_old).then(res=>{
                    if(res.status!=200){
                        return this.$message.error('删除部门失败')
                    }
                    this.departmentList()
					bian_log(`删除部门${department_old}`)
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });    
        },
        closeEdit(){
            this.dialogVisibleEdit=false;
            this.departmentEdit='';
        },
        editDepartment(){
            edit_department(this.editId,this.departmentEdit,this.company,this.editBranch).then(res=>{
                if(res.status!=200){
                    return this.$message.error('编辑部门失败')
                }
                this.dialogVisibleEdit=false;
				bian_log(`编辑部门${this.departmentEdit}`)
                this.departmentEdit=''
                this.departmentList()
            })
        },
    },
    created() {
        let company=sessionStorage.getItem('company')
        this.company=company;
        this.departmentList()
    },
};
</script>
<style lang='less' scoped>
.catopt{
    display: flex;
    justify-content: space-around;
}
.el-cascader{
    width: 100%;
}
</style>